<script setup>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import {
  NConfigProvider,
  NInput,
  NDatePicker,
  NSpace,
  NCard,
  NButton,
} from "naive-ui";

const router = useRouter();
const route = useRoute();

console.log(route.path);
</script>
<template>
  <section class="page bg-red-900">
    <section>
      <!-- tailwind add h1 title and copy to the bottom of the page using flex lorem -->
      <div class="flex flex-col justify-end items-start h-screen">
        <div class="text-start p-5 md:p-10">
          <h1 class="text-5xl md:text-7xl uppercase my-5">FPO.ai</h1>
          <p class="text-2xl md:text-3xl text-left my-1">
            AI Powered Figma Plugin for Image and Copy Generation
          </p>
          <a href="https://fpo.ai" target="_blank" class="text-2xl 0">FPO.ai</a>
        </div>
      </div>
    </section>
  </section>
</template>

<style scoped>
.page {
  color: white;
  font-family: kanit;
  font-weight: 100;
  align-items: start;
  justify-items: start;
  height: 100vh;
  scroll-snap-align: start;
  /* add background image fill div */
  background-image: url("../assets/bk/bk-fpo.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* media */
@media (max-width: 600px) {
  .page {
    height: 100% !important;
    font-weight: 100;
  }
}
</style>
