import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import App from "../App.vue";
import Welcome from "../components/Welcome.vue";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: App,
  },
  {
    path: "/Welcome",
    name: "Welcome",
    component: Welcome,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
