<script setup>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import {
  NConfigProvider,
  NInput,
  NDatePicker,
  NSpace,
  NCard,
  NButton,
} from "naive-ui";
// import { AddOutline } from "@vicons/utils"; // vue3
import { ArrowDownCircleOutline } from "@vicons/ionicons5";

// components: {
//   AddOutline;
// }

const router = useRouter();
const route = useRoute();

console.log(route.path);
</script>

<template>
  <section class="page">
    <section>
      <div class="flex flex-col justify-center items-center">
        <img src="../assets/logo.png" alt="" class="h-auto" />
        <n-icon
          size="40"
          color="white"
          :component="ArrowDownCircleOutline"
          class="absolute bottom-0 mb-10 animate-bounce-short down"
        />
      </div>
    </section>
  </section>
</template>

<style scoped lang="scss">
.page {
  display: flex;
  align-items: center;
  justify-items: center;
  height: 100vh;
  scroll-snap-align: start;
  margin: 0 !important;
  padding: 0;
  background-color: #1a1818;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgb(17, 17, 17);
  -webkit-animation-name: animate1; /* Chrome, Safari, Opera */
  -webkit-animation-duration: 7s; /* Chrome, Safari, Opera */
  animation-name: animate1;
  animation-duration: 22s;
}

img {
  height: 320px;
}
/* Chrome, Safari, Opera */
@-webkit-keyframes animate1 {
  0% {
    background-color: rgb(35, 35, 35);
  }
  25% {
    background-color: rgb(34, 14, 35);
  }
  50% {
    background-color: rgb(33, 33, 47);
  }
  100% {
    background-color: rgb(17, 17, 17);
  }
}

/* Standard syntax */
@keyframes animate1 {
  0% {
    background-color: rgb(35, 35, 35);
  }
  25% {
    background-color: rgb(34, 14, 35);
  }
  50% {
    background-color: rgb(33, 33, 47);
  }
  100% {
    background-color: rgb(17, 17, 17);
  }
}

//media query for mobile
@media only screen and (max-width: 600px) {
  img {
    width: 100% !important;
    height: auto !important;
  }
  .page {
    height: 100% !important;
  }
  .down {
    display: none;
  }
}
</style>
