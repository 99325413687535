<template>
  <section>
    <slot />
  </section>
</template>

<style>
html,
body {
  scroll-snap-type: y mandatory;
}

/* media */
@media (max-width: 600px) {
  html,
  body {
    scroll-snap-type: none !important;
  }
}
</style>

<style scoped>
/* .container >>> .page:nth-child(even) {
  flex-direction: row-reverse;
  background: #eee;
} */
</style>
