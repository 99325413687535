<script setup>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import {
  NConfigProvider,
  NInput,
  NDatePicker,
  NSpace,
  NCard,
  NButton,
  NAlert,
} from "naive-ui";
import axios from "axios";

const formEmail = ref("");
const formMessage = ref("");
const formName = ref("");
const showEmailAlert = ref(false);

//send email via postmark api

const sendEmail = async () => {
  const pl = {
    formEmail: formEmail.value,
    formName: formName.value,
    formMessage: formMessage.value,
  };

  // console.log(pl);
  // send to api endpoint send using async await
  const response = await axios.post("/send", pl);
  if (response.status == 200) {
    console.log("email sent");
    showEmailAlert.value = true;
  } else {
    console.log("email not sent");
  }
};

const router = useRouter();
const route = useRoute();

console.log(route.path);
</script>
<template>
  <section class="page bg-gray-800">
    <section>
      <div class="container1">
        <div class="top">
          <div class="bg-gray-900 h-full py-10">
            <div class="mx-auto max-w-7xl">
              <h2 class="text-center text-lg leading-8 text-white">
                Proud to Have Pushed the Boundaries
              </h2>
              <div
                class="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5"
              >
                <img
                  class="col-span-2 max-h-6 w-full object-contain lg:col-span-1"
                  src="../assets/logos/cmu.svg"
                  alt="Transistor"
                  width="158"
                  height="48"
                />
                <img
                  class="col-span-2 max-h-7 md:max-h-12 w-full object-contain lg:col-span-1"
                  src="../assets/logos/ecr.svg"
                  alt="Reform"
                  width="158"
                  height="48"
                />

                <img
                  class="col-span-2 max-h-10 w-full object-contain sm:col-start-2 lg:col-span-1"
                  src="../assets/logos/army.svg"
                  alt="SavvyCal"
                  width="158"
                  height="48"
                />
                <img
                  class="col-span-2 max-h-5 w-full object-contain lg:col-span-1"
                  src="../assets/logos/nasa.png"
                  alt="Tuple"
                  width="158"
                  height="48"
                />
                <img
                  class="col-span-2 col-start-2 max-h-6 w-full object-contain sm:col-start-auto lg:col-span-1"
                  src="../assets/logos/jpl.png"
                  alt="Statamic"
                  width="158"
                  height="48"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="content1 grid grid-flow-row grid-cols-1 md:grid-cols-2">
          <form action="#" method="POST" class="px-6 md:pt-[50px]">
            <div class="p-5">
              <h1 class="text-3xl">Connect</h1>
              <div class="sm:grid-cols-2 mt-10">
                <div>
                  <label
                    for="first-name"
                    class="block text-sm leading-6 text-white"
                    >First name</label
                  >
                  <div class="mt-2.5">
                    <input
                      v-model="formName"
                      type="text"
                      name="first-name"
                      id="first-name"
                      autocomplete="given-name"
                      class="block w-full rounded-md border-0 bg-white/5 py-2 px-3.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div class="sm:col-span-2">
                  <label
                    for="email"
                    class="block text-sm leading-6 text-white mt-2"
                    >Email</label
                  >
                  <div class="mt-2.5">
                    <input
                      v-model="formEmail"
                      type="email"
                      name="email"
                      id="email"
                      autocomplete="email"
                      class="block w-full rounded-md border-0 bg-white/5 py-2 px-3.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div class="sm:col-span-2">
                  <label
                    for="message"
                    class="block text-sm leading-6 text-white mt-2"
                    >Message</label
                  >
                  <div class="mt-2.5">
                    <textarea
                      v-model="formMessage"
                      name="message"
                      id="message"
                      rows="4"
                      class="block w-full rounded-md border-0 bg-white/5 py-2 px-3.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="mt-8 flex justify-end">
                <n-button
                  @click="sendEmail"
                  type="submit"
                  class="rounded-md bg-indigo-500 px-3.5 py-2.5 text-center text-sm text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                >
                  Send message
                </n-button>
              </div>
              <n-alert
                type="success"
                show-icon
                v-if="showEmailAlert"
                class="my-10"
              >
                Thanks! Will get back to you...
              </n-alert>
            </div>
          </form>

          <div class="text-left p-10 bg-opacity-20 bg-black text-2xl">
            <p class="mt-10">
              Specializing in designing and developing intuitive interaction
              systems for complex models.
            </p>
            <ul class="block text-left my-12">
              <li>Comprehensive user-centered design process</li>
              <li>Highly collaborative & real-time engagements</li>
              <li>
                Rapid interactive prototypes deployed to a wide range of form
                factors
              </li>
              <li>
                Exploratory code development in multiple languages to mitigate
                risk
              </li>
            </ul>

            <p>
              Please reach out if you have any questions or would like to set
              time to discuss your project.
            </p>

            <div class="mt-10 text-sm">
              <div class="block my-5 tools">Tools & Focus</div>
              <ul class="tools">
                <li>Figma</li>
                <li>Vue</li>
                <li>ROS</li>
                <li>Python</li>
                <li>Unreal</li>
                <li>Unity</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<style scoped lang="scss">
.page {
  color: white;
  font-family: kanit;
  align-items: start;
  justify-items: start;

  scroll-snap-align: start;
  /* add background image fill div */
}
.container1 {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 0.22fr 1fr;

  gap: 0px 0px;

  grid-template-areas:
    "top"
    "content1";
  height: 100%;
}

.top {
  grid-area: top;
  background: green;
}

.content1 {
  grid-area: content1;
  background: #3f3f3f;
}

.footerr {
  grid-area: footerr;
  background: #000;
}

li {
  list-style: none;
  margin: 18px 0;
  color: #1abcfe;
  line-height: 21px;
  /* background with alpha */
  background: rgba(0, 0, 0, 0.3);
  padding: 10px;
}

ul.tools {
  li {
    display: inline-block;
    background: rgba(0, 0, 0, 0.3);
    padding: 10px 25px;
    margin-right: 10px;
    border-radius: 10px;
    margin: 5px;
    color: white;
  }
}

//media queries
@media (max-width: 600px) {
  .container1 {
    height: auto;
  }

  .page {
    height: 100% !important;
  }

  ul.tools {
    li {
      display: block;
      text-align: center;
    }
  }

  .tools {
    text-align: center;
  }
}
</style>
